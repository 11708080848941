import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source'];

  copy() {
    const content = this.sourceTarget.textContent;
    navigator.clipboard.writeText(content).then(() => true);
  }
}
